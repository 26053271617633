import '../scss/main.scss';
// import "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";
import { bottom, flip, viewport } from '@popperjs/core';
// Export initBootstrap for use in other files
export { initBootstrap, bottom, viewport };

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger.js';
import { SplitText } from 'gsap/SplitText.js';

gsap.registerPlugin(ScrollTrigger, SplitText,);

// FONCTION CHANGE COULEUR TEXTE



// FONCTION
export default function initIndex() {
    //     console.log("initIndex called"); // Log to check if this function is called

    // Liste des mots à animer
    const words = ["Bonjour", "Salut", "Hello", "Hola", "Ciao"];

    // Sélection du conteneur
    const wordContainer = document.getElementById('word-container');
    //     console.log("wordContainer:", wordContainer); // Log to check if the container exists

    if (!wordContainer) return; // Return early if the container doesn't exist

    // Fonction pour générer un caractère spécial aléatoire
    function getRandomChar() {
        const specialChars = "!@#$%^&*()_+-=[]{}|;':,.<>?";
        return specialChars.charAt(Math.floor(Math.random() * specialChars.length));
    }

    // Fonction pour animer les mots avec GSAP
    function animateWords() {
        // console.log("Starting word animation"); // Log to check if the function is called
        let currentIndex = 0;

        // Fonction pour changer de mot avec animation entre les mots
        function changeWord() {
            //     console.log("Changing word"); // Log to check if the function is called
            // Afficher le mot actuel de la liste
            let word = words[currentIndex];
            let wordLength = word.length;

            // Supprimer le contenu précédent
            wordContainer.textContent = '';

            // Créer des spans pour chaque caractère du mot
            for (let i = 0; i < wordLength; i++) {
                let span = document.createElement('span');
                span.textContent = word[i];
                wordContainer.appendChild(span);

                // Animation pour chaque caractère avec un caractère spécial aléatoire
                gsap.to(span, {
                    duration: 0.2,
                    onStart: () => { span.textContent = getRandomChar(); }, // Changer en caractère spécial aléatoire au début de l'animation
                    onComplete: () => { span.textContent = word[i]; }, // Revenir au caractère original à la fin de l'animation
                    delay: i * 0.05 // Décalage pour une animation fluide
                });
            }

            // Mettre à jour l'index pour le prochain mot
            currentIndex = (currentIndex + 1) % words.length;
        }

        // Appeler la fonction pour le premier mot
        changeWord();

        // Changer le mot toutes les 2 secondes
        setInterval(changeWord, 2000);
    }

    // Lancer l'animation lorsque la page est chargée
    animateWords();






    
    // // TEXTE CHANGE DE COULEUR
    // const split = new SplitText(".text-wrapper p", {
    //     type: "words"
    // });

    // const tl = gsap
    //     .timeline({
    //         scrollTrigger: {
    //             // trigger: "#scrollText",
    //             start: "top top",
    //             // end: "top 20%",
    //             end: "+=150%",
    //             pin: true,
    //             // pinSpacing: false,
    //             scrub: 0.75,
    //             // markers: true
    //         }
    //     })

    // // Définir l'animation pour changer l'opacité et la couleur des caractères
    // tl.fromTo(
    //     split.words,
    //     {
    //         opacity: 0.2 // Opacité initiale
    //     },
    //     {
    //         opacity: 1, // Opacité finale
    //         color: "var(--light)", // Couleur finale
    //         stagger: 0.1
    //     },
    //     0
    // );

}




////////////////
// FIN EXPORT //
////////////////
